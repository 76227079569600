import axios from "axios";

export const baseUrl = "https://admin.zielonydzieciol.com/";
export const imgUrl = `${baseUrl}img/uploads/`;

export const getAttractions = async function () {
    return await axios.get(`${baseUrl}attractions`);
}

export const getInterior = async function () {
    return await axios.get(`${baseUrl}interior`);
}

export const getImage = async function (imgname: string) {
    let imgblob = (await axios.get(`${imgUrl}${imgname}`, { responseType: 'blob' })).data;
    return URL.createObjectURL(imgblob);
}

export const getSurroundings = async function () {
    return await axios.get(`${baseUrl}surroundings`);
}

export const getHomeInfo = async function () {
    return await axios.get(`${baseUrl}home`);
}

export const getPlaceInfo = async function () {
    return await axios.get(`${baseUrl}place`);
}