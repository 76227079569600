import classNames from "classnames";

const Nearby = () => {
  return (
    <div className="flex flex-col gap-5">
      <div className="text-xl poiret-one-regular stmarie-thin-light !font-bold">
        MIEJSCA W POBLIŻU
      </div>
      <div className="flex flex-col gap-1 rajdhani-light">
        <div className="text-xl rajdhani-regular">50.8km</div>
        <div className="text-sm">
          Zapora Solina{" "}
          <a
            className="decoration-1 underline-offset-4 underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://maps.app.goo.gl/J3kdFbH3kLTmBL678"
          >
            (link)
          </a>
          <br />
          ok. 55min samochodem
        </div>
      </div>
      <div className="flex flex-col gap-1 rajdhani-light">
        <div className="text-xl rajdhani-regular">18.8km</div>
        <div className="text-sm">
          Połonina Wietlińska{" "}
          <a
            className="decoration-1 underline-offset-4 underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://maps.app.goo.gl/aEYBveWS9HvUw8mk7"
          >
            (link)
          </a>
          <br />
          ok. 25min samochodem
        </div>
      </div>
      <div className="flex flex-col gap-1 rajdhani-light">
        <div className="text-xl rajdhani-regular">16.1km</div>
        <div className="text-sm">
          Zagroda Pokazowa Żubrów{" "}
          <a
            className="decoration-1 underline-offset-4 underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://maps.app.goo.gl/X4bFKsch2nieGHtG6"
          >
            (link)
          </a>
          <br />
          ok. 17min samochodem
        </div>
      </div>
    </div>
  );
};

export const VisitUs = () => {
  return (
    <div className="flex flex-col bg-[#fffcf1] py-20 gap-20 text-[#b08353]">
      <div className="container m-auto px-5">
        <div className="flex flex-col gap-20">
          <div className="text-6xl rajdhani-regular poiret-one-regular stmarie-thin-light">
            Dojazd
          </div>
          <div className={visitPlaceClasses}>
            <div className="info flex flex-col gap-10 text-[#b08353] z-10 justify-between min-w-[220px]">
              <div className="flex flex-col gap-5">
                <div className="text-xl poiret-one-regular stmarie-thin-light !font-bold">
                  NASZ ADRES
                </div>
                <div className="text-sm rajdhani-light">
                  Chmiel 1B <br />
                  38-713 Lutowiska
                </div>
              </div>
              <div className="hidden lg:block">
                <Nearby />
              </div>
            </div>
            <div className="z-10 w-full h-[30vh] lg:h-[50vh]">
              <iframe
                className="lg:flex-1 w-full h-full"
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2605.8250884357067!2d22.610020477366803!3d49.22284417498852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473be7bb8ed5debf%3A0x9bd79540b94754a0!2sZielony%20Dzi%C4%99cio%C5%82%20Noclegi!5e0!3m2!1spl!2spl!4v1716198129731!5m2!1spl!2spl"
                // width="600"
                // height="450"
                // style="border:0;"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="hidden max-lg:block">
              <Nearby />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const visitPlaceClasses = classNames(
  "relative",
  "w-full",
  "lg:w-[70vw]",
  "m-auto",
  "flex",
  "max-lg:flex-col",
  "justify-around",
  "gap-10",
  "lg:gap-20",
  "p-10",
  "lg:p-20",
  "border",
  "border-[#b08353]",
  "before:border",
  "before:border-[#b08353]",
  "before:absolute",
  "before:top-[6px]",
  "before:left-[6px]",
  "before:right-0",
  "before:bottom-0",
  "before:w-[calc(100%_-_12px)]",
  "before:h-[calc(100%_-_12px)]",
);
