import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAtom, atom } from "jotai";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, FreeMode } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { getImage, getInterior, imgUrl } from "../utils";
import { Loader } from "./Loader";

export const currentSliderSlide = atom(0);

export const Slider = () => {
  const sliderRef = useRef<any>(null);
  const [_, setCurrentSlide] = useAtom(currentSliderSlide);
  const [interior, setInterior] = useState<any>(undefined);
  const [imgState, setImgState] = useState<{[key: string]: string}>({});
  const [images, setImages] = useState<{[key: number]: any}>({});

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const showImage = function(e:any){
    if(!e?.target?.attributes['data-key'] || !(!imgState[e.target.attributes['data-key'].value] || imgState[e.target.attributes['data-key'].value] == 'thumb'))
      return;

    const imgno = e.target.attributes['data-key'].value;

    setImgState({
      ...imgState,
      [imgno] : 'loader'
    });

    (async () => {
      if(!images[imgno]){
        const url = await getImage(e.target.attributes['data-image'].value);
        setImages({
          ...images,
          [`${imgno}`]: url
        });
        setImgState({
          ...imgState,
          [imgno] : 'image'
        });
      }else{
        console.log('podmieniam', imgState, imgno,  {
          ...imgState,
          [imgno] : 'image'
        })
        setImgState({
          ...imgState,
          [imgno] : 'image'
        });
      }
    })()
  };

  useEffect(() => {
    (async () => {
      const ret = await getInterior();
      setInterior(ret.data);
    })()
  }, []);

  const hideModal = function() {
    const newState = Object.keys(imgState).reduce((a,c) => ({
      ...a,
      [c]: imgState[c] == 'image' ? "thumb" : imgState[c]
    }), {});
    setImgState(newState);
  };

  const currentImage = Object.keys(imgState).find((i) => imgState[i] == 'image') as number | undefined;

  return (
    <>
    {interior ?
      <div className="">
        <section className={`interiormodal${currentImage ? ' active' : ''}`} onClick={hideModal}>
          {currentImage &&
            <img src={images[currentImage]}></img>
          }
        </section>
        <Swiper
          onSlideChange={() =>
            setCurrentSlide(sliderRef.current.swiper.activeIndex)
          }
          ref={sliderRef}
          spaceBetween={30}
          modules={[Navigation, Autoplay, FreeMode]}
          slidesPerView={1.5}
          navigation
          // autoplay={{ delay: 5000 }}
          speed={700}
          freeMode={true}
          loop
          className="h-full"
          breakpoints={{
            768: {
              slidesPerView: 3.7,
            },
          }}
        >
          {interior.map((int:any, index:number) => {
            return <SwiperSlide className="!h-auto" onClick={showImage} key={`${index}`}>
                { !imgState[index] || imgState[index] == 'thumb' || imgState[index] == 'image'?
                <img
                  className="object-cover w-full h-full rounded-lg"
                  src={`${imgUrl}${int.thumb}`}
                  alt={`slide_${index}`}
                  data-image={int.image}
                  data-key={index}
                />
                : <><div className="arealoader"><Loader /></div></>
                }
            </SwiperSlide>
          })}
        </Swiper>
        <div className="prev-arrow" onClick={handlePrev} />
        <div className="next-arrow" onClick={handleNext} />
      </div>
      : <><div className="arealoader"><Loader /></div></>
    }
    </>
  );
};
