export const textVariants = {
  initial: {
    opacity: "1",
    width: "auto",
    // display: "block",
  },
  scrolled: {
    opacity: "0",
    width: "0px",
    // display: "none",
  },
};

export const burgerVariants = {
  initial: {
    width: "150px",
  },
  scrolled: {
    width: "60px",
  },
};

export const burgerElementsWrapperVariants = {
  initial: {
    gap: "20px",
  },
  scrolled: {
    gap: "0px",
  },
};

export const menuVariants = {
  initial: {
    height: "0px",
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
  open: {
    height: "100%",
    transition: {
      duration: 0.5,
    },
  },
};

export const menuElementVariants = {
  initial: {
    opacity: 0,
    transform: "translateY(50px)",
  },
  open: {
    opacity: 1,
    transform: "translateY(0px)",
    transition: {
      duration: 1,
      delay: 0.7,
    },
  },
  exit: {
    // opacity: "0",
    transform: "translateY(30px)",
    transition: {
      duration: 0.4,
    },
  },
};

export const menuImageVariants = {
  initial: {
    opacity: 0,
    transform: "translateY(30px)",
    transition: {
      duration: 0.4,
    },
  },
  open: {
    opacity: 1,
    transform: "translateY(0px)",
    transition: {
      duration: 1,
      delay: 0.7,
    },
  },
};

export const menuBorderVariants = {
  initial: {
    clipPath: "inset(0px 0px 100%)",
  },
  open: {
    clipPath: "inset(0px)",
  },
};
