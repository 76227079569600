import classNames from "classnames";
import { motion } from "framer-motion";
import { atom, useAtom } from "jotai";
import { Loader } from "./Loader";
import { useEffect, useState } from "react";
import { getAttractions, imgUrl } from "../utils";

export const attractionsModal = atom<number>(0);

const SvgClose = () => {
  return (
    <svg
      className="w-8 h-8 fill-white"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      height="100"
      viewBox="0 0 50 50"
    >
      <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
    </svg>
  );
};

export const Attractions = () => {
  const [modal, setModal] = useAtom<number>(attractionsModal);
  const [attractions, setAttractions] = useState<any>(undefined);

  const handleClick = (modalNumber: number) => {
    setModal(modalNumber);
    document.body.style.overflowY = "hidden";
  };

  const handleModalClose = (modalNumber: number) => {
    setModal(modalNumber);
    document.body.style.overflowY = "";
  };

  useEffect(() => {
    (async () => {
      const ret = await getAttractions();
      setAttractions(ret.data);
    })()
  }, []);

  return (
    <div
      id="atrakcje"
      className={mainWrapperClassnames}
    >
      <div className={titleClasses}>
        ATRAKCJE
      </div>
      {attractions ?
        <div className="flex max-lg:flex-col gap-10 lg:h-[60vh]">
          {attractions.map((attraction:any, index:number) => {
            return <div className={wrapperClass} key={`attractions_${index+1}`}>
            <img
              className={imgClass}
              src={`${imgUrl}${attraction.image}`}
              alt={`attractions_${index+1}`}
            />
            <button onClick={() => handleClick(index+1)} className={buttonClasses}>
              Więcej
            </button>
            <motion.div
              className={classNames(
                textCommonClasses,
                modal === 0 && textClasses,
                modal === (index+1) && textClassesModal,
              )}
            >
              <div
                onClick={() => handleModalClose(0)}
                className={classNames(
                  modal === 0 && "hidden",
                  modal === (index+1) && "text-4xl absolute top-4 right-4",
                )}
              >
                <SvgClose />
              </div>
              <div className="max-lg:overflow-auto max-lg:max-h-[80vh] greenlinks" dangerouslySetInnerHTML={{ __html: attraction.content }}>
              </div>
            </motion.div>
          </div>
          })}
        </div>
        : <><div className="arealoader"><Loader /></div></>
      }
    </div>
  );
};

const mainWrapperClassnames = classNames(
  "bg-[#202e21]",
  // "bg-[#364237]",
  "lg:px-10",
  "py-10",
  "flex",
  "flex-col",
  "gap-10",
  "relative"
);
const titleClasses = classNames(
  "h-[10vh]",
  "flex",
  "items-center",
  "justify-center",
  "text-white",
  "text-4xl",
  "lg:text-7xl",
  "rajdhani-regular",
  "poiret-one-regular",
  "stmarie-thin-light",
);

const wrapperClass = classNames(
  "relative",
  "flex-1",
  "transition-all",
  "duration-500",
  "sm:hover:flex-[2]",
  "group",
);

const textCommonClasses = classNames(
  "flex",
  "justify-center",
  "items-center",
  "top-0",
  "bottom-0",
  "left-0",
  "right-0",
  "text-lg",
  "leading-normal",
  "text-white",
  "rajdhani-regular",
);

const textClasses = classNames(
  "group-hover:bg-[#202e21]/90",
  "absolute",
  "transition-all",
  "opacity-0",
  // "text-[0px]",
  "lg:group-hover:opacity-100",
  // "lg:group-hover:text-[20px]",
  "delay-300",
  "px-10",
  "hidden",
  "lg:flex",
);

const imgClass = classNames(
  "w-full",
  "h-full",
  "max-lg:h-[300px]",
  "object-cover",
  "lg:rounded-lg",
);

const textClassesModal = classNames(
  "fixed",
  "top-0",
  "bottom-0",
  "left-0",
  "right-0",
  "z-10",
  "px-5",
  // "bg-[#202e21]",
  "bg-[#b08353]",
  "flex",
  "justify-center",
  "items-center",
  "text-[17px]",
  "z-30",
  "transition-all",
);

const buttonClasses = classNames(
  "lg:hidden",
  "absolute",
  "bottom-5",
  "left-[calc(50%_-_100px)]",
  "w-[200px]",
  "flex",
  "justify-center",
  "items-center",
  "bg-[#202e21]",
  // "bg-[#b08353]",
  "bg-[#364237]/70",
  "text-white",
  "rounded",
  "py-1",
  "px-2",
  "rajdhani-regular",
);
