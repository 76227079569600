import { useEffect, useState } from "react";
import { Loader } from "./Loader";
import { getSurroundings, imgUrl } from "../utils";

export const Area = () => {
  const [surroundings, setSurroundings] = useState<any>(undefined);

  useEffect(() => {
    (async () => {
      const ret = await getSurroundings();
      setSurroundings(ret.data);
    })()
  }, []);

  return (
    <>
      {surroundings ?
        <div
          className="text-[#b08353] lg:text-2xl py-20 rajdhani-regular"
        >
          <div className="container m-auto px-5">
            <div className="flex flex-col gap-20 lg:gap-40">
              <div className="flex max-lg:flex-col gap-10">
                <div className="flex max-lg:flex-col gap-10 lg:gap-20">
                  <div>
                    <img
                        className="rounded-lg lg:w-3/4 lg:h-[400px] object-cover"
                        src={`${imgUrl}${surroundings.img1}`}
                        alt="place"
                    />
                    <div className="mt-10 text-2xl" dangerouslySetInnerHTML={{ __html: surroundings.content }}></div>
                  </div>
                  <img
                      className="rounded-lg lg:w-1/3 h-auto object-cover lg:mt-20"
                      src={`${imgUrl}${surroundings.img2}`}
                      alt="place_2"
                  />
                </div>
              </div>
              <div>
                <img
                    className="rounded-lg lg:max-w-[75%] h-auto object-cover m-auto"
                    // src="/images/outdoor/outdoor_6.JPEG"
                    src={`${imgUrl}${surroundings.img3}`}
                    alt="outdoor_1"
                />
              </div>
            </div>
          </div>
        </div>
        :
        <div className="arealoader">
          <Loader />
        </div>
      }
    </>
  );
};
