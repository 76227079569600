import React, { useEffect, useRef, useState } from "react";
import {
  motion,
  useScroll,
  useMotionValueEvent,
  AnimatePresence,
} from "framer-motion";
import { Slider } from "./Slider";
import { Header } from "./Header";
import { getHomeInfo, imgUrl } from "../utils";
import { Loader } from "./Loader";

// const Heading = ({ children }: { children: any }) => {
//   return (
//     <div className="overflow-hidden lg:px-20">
//       <AnimatePresence>
//         <motion.h2
//           className="translate-y-10 text-7xl rajdhani-regular"
//           animate={{ translateY: ["100%", "0%"] }}
//           transition={{ duration: 0.5 }}
//         >
//           {children}
//         </motion.h2>
//       </AnimatePresence>
//     </div>
//   );
// };

const Text = ({ children }: { children: any }) => {
  return (
    <div className="overflow-hidden lg:pr-20">
      <AnimatePresence>
        <motion.h2
          className="translate-y-10 text-2xl rajdhani-regular text-[#b08353]"
          animate={{ translateY: ["-100%", "0%"] }}
          transition={{ duration: 0.7 }}
        >
          {children}
        </motion.h2>
      </AnimatePresence>
    </div>
  );
};

export const PlaceInfo = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [_, setIsScrolled] = useState(false);
  const [homeInfo, setHomeInfo] = useState<any>(undefined);
  const { scrollY } = useScroll();
  useMotionValueEvent(scrollY, "change", (latest) => {
    if (ref.current) {
      if (ref.current.getBoundingClientRect().top < 300) {
        setIsScrolled(true);
      }
    }
  });

  useEffect(() => {
    (async () => {
      const ret = await getHomeInfo();
      setHomeInfo(ret.data);
    })()
  }, []);

  return (
    <>
    {homeInfo?
      <div
        id="domek"
        ref={ref}
        className="flex flex-col pt-10 lg:pt-40 bg-[#FFFCF4] bg-[#fffcf1] text-[#b08353]"
      >
        <div className="flex flex-col gap-20">
          <div className="container m-auto px-5">
            <div className="flex max-lg:flex-col max-lg:gap-10 h-full">
              <div className="text-4xl text-amber-950 lg:w-1/2 flex flex-col gap-10">
                <Text><p dangerouslySetInnerHTML={{ __html: homeInfo.content }}></p></Text>
              </div>
              <div className="lg:w-1/2">
                <img
                  className="rounded-lg"
                  src={`${imgUrl}${homeInfo.img}`}
                  alt="outdoor_7"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-10 lg:gap-20">
            <div className="container m-auto px-5">
              <div className="text-6xl poiret-one-regular stmarie-thin-light">
                Wnętrze
              </div>
            </div>
            <div className="pl-5 pr-5 lg:pl-[7%] lg:pr-[7%]">
              <Header />
              <Slider />
            </div>
          </div>
        </div>
      </div>
      :
      <div className="arealoader">
        <Loader />
      </div>
      }
    </>
  );
};
