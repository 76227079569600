import React from "react";
import "./App.css";
import classNames from "classnames";
import { useAtom } from "jotai/index";
import { Entrance } from "./components/Entrance";
import { Place } from "./components/Place";
import { PlaceInfo } from "./components/PlaceInfo";
import { VisitUs } from "./components/VisitUs";
import { Area } from "./components/Area";
import { Attractions, attractionsModal } from "./components/Attractions";
import { Footer } from "./components/Footer";

function App() {
  const [modal] = useAtom<number>(attractionsModal);
  return (
    <>
      <Entrance />
      <div
        className={classNames(
          "z-10 relative bg-[#fffcf4]",
          modal !== 0 && "z-30",
        )}
      >
        <div id="miejsce" className={sectionClasses}>
          ODKRYJ NASZE MIEJSCE
        </div>
        <Place />
        {/*<SliderIndoor />*/}
        <div id="domek" className={sectionClasses}>
          NASZ DOMEK
        </div>
        <PlaceInfo />
        <VisitUs />
        <div id="area" className={sectionClasses}>
          OKOLICA
        </div>
        <Area />
        <Attractions />
        <Footer />
      </div>
    </>
  );
}

export default App;

const sectionClasses = classNames(
  "p-5 min-h-[10vh] flex items-center justify-center bg-[#202e21] bg-[#364237] text-white text-4xl rajdhani-regular stmarie-thin-light text-center",
);
