export const entranceWrapperVariants = {
  initial: {
    background: ["#364237", "#19241a"],
    transition: { duration: 1 },
  },
};

export const entranceImageWrapperVariants = {
  initial: {
    opacity: [0.5, 1],
    clipPath: ["inset(20% round 20px)", "inset(0% round 0px)"],
    transition: {
      delay: 1,
      duration: 2,
      ease: "backIn",
    },
  },
};

export const entranceImageVariants = {
  initial: {
    scale: [1.5, 1],
    transition: {
      delay: 1,
      duration: 2,
      ease: "backIn",
    },
  },
};

export const entranceHeadingVariants = {
  initial: {
    transform: [
      "translateY(-160%) rotate(5deg) translateZ(0px)",
      "translateY(0%) rotate(0deg) translateZ(0px)",
    ],
    transition: {
      delay: 2.5,
      duration: 1,
    },
  },
};

export const entranceHeadingSecondVariants = {
  initial: {
    transform: [
      "translateY(180%) rotate(-5deg) translateZ(0px)",
      "translateY(0%) rotate(0deg) translateZ(0px)",
    ],
    transition: {
      delay: 2.5,
      duration: 1,
    },
  },
};

export const entranceMiddleTextVariants = {
  initial: {
    transform: [
      "translateY(270%) rotate(-5deg) translateZ(0px)",
      "translateY(0%) rotate(0deg) translateZ(0px)",
    ],
    transition: {
      delay: 2.5,
      duration: 1,
    },
  },
};
