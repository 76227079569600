import classNames from "classnames";

export const Footer = () => {
  return (
    <footer id="kontakt" className={footerClasses}>
      <div className={footerLogoClasses}>
        <img
          className="w-[200px]"
          src="/images/logo_no_bg.png"
          // src="/images/logo_second.jpg"
          alt="footer_logo"
        />
        <div className="flex flex-col gap-1 justify-center items-center text-center text-[#676E5C]">
          <div>Domek na wynajem w Dolinie Sanu w Bieszczadach.</div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="decoration-1 underline-offset-4 underline"
            href="https://maps.app.goo.gl/RbfK668ErEBu3UdWA"
          >
            (Mapa)
          </a>
        </div>
      </div>
      <div className="flex max-lg:flex-col justify-evenly max-lg:gap-10 p-5 lg:min-h-[500px] relative">
        <div className="flex flex-col justify-center flex-1 text-center">
          <div>Zielony Dzięcioł Noclegi</div>
          <div>Chmiel 1B</div>
          <div>38-713 Lutowiska</div>
          <div>
            <span>Tel: </span>
            <a href="tel:+48505953626">+48 505 953 626</a>
          </div>
          <div>
            <span>Email: </span>
            <a
              className="decoration-1 underline-offset-4 underline break-words"
              href="mailto:zielonydzieciol.domek@gmail.com"
            >
              zielonydzieciol.domek@gmail.com
            </a>
          </div>
        </div>
        <div className="max-lg:hidden border-b lg:border-l border-[#676e5c] absolute h-[calc(100%_-_40px)]" />
        <div className="flex flex-col justify-center items-center flex-1">
          {/*<a href="">instagram</a>*/}
          <a
            href="https://www.facebook.com/profile.php?id=100094072116823"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="fill-[#E0E2DE] transition-all sm:hover:fill-[rgba(59,89,152,1)] sm:hover:drop-shadow-[2px_4px_6px_rgba(59,89,152,1)]"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 50 50"
            >
              <path d="M 25 3 C 12.861562 3 3 12.861562 3 25 C 3 36.019135 11.127533 45.138355 21.712891 46.728516 L 22.861328 46.902344 L 22.861328 29.566406 L 17.664062 29.566406 L 17.664062 26.046875 L 22.861328 26.046875 L 22.861328 21.373047 C 22.861328 18.494965 23.551973 16.599417 24.695312 15.410156 C 25.838652 14.220896 27.528004 13.621094 29.878906 13.621094 C 31.758714 13.621094 32.490022 13.734993 33.185547 13.820312 L 33.185547 16.701172 L 30.738281 16.701172 C 29.349697 16.701172 28.210449 17.475903 27.619141 18.507812 C 27.027832 19.539724 26.84375 20.771816 26.84375 22.027344 L 26.84375 26.044922 L 32.966797 26.044922 L 32.421875 29.564453 L 26.84375 29.564453 L 26.84375 46.929688 L 27.978516 46.775391 C 38.71434 45.319366 47 36.126845 47 25 C 47 12.861562 37.138438 3 25 3 z M 25 5 C 36.057562 5 45 13.942438 45 25 C 45 34.729791 38.035799 42.731796 28.84375 44.533203 L 28.84375 31.564453 L 34.136719 31.564453 L 35.298828 24.044922 L 28.84375 24.044922 L 28.84375 22.027344 C 28.84375 20.989871 29.033574 20.060293 29.353516 19.501953 C 29.673457 18.943614 29.981865 18.701172 30.738281 18.701172 L 35.185547 18.701172 L 35.185547 12.009766 L 34.318359 11.892578 C 33.718567 11.811418 32.349197 11.621094 29.878906 11.621094 C 27.175808 11.621094 24.855567 12.357448 23.253906 14.023438 C 21.652246 15.689426 20.861328 18.170128 20.861328 21.373047 L 20.861328 24.046875 L 15.664062 24.046875 L 15.664062 31.566406 L 20.861328 31.566406 L 20.861328 44.470703 C 11.816995 42.554813 5 34.624447 5 25 C 5 13.942438 13.942438 5 25 5 z"></path>
            </svg>
          </a>
        </div>
      </div>
    </footer>
  );
};

const footerClasses = classNames(
  "bg-[#202e21]",
  // "bg-[#364237]",
  "bg-[#202E21]",
  // "text-[#202e21]",
  // "bg-[#fffcf4]",
  "text-[#E0E2DE]",
  "rajdhani-regular",
  "text-[16px]",
  "leading-6",
  "lg:text-xl",
);

const footerLogoClasses = classNames(
  "flex",
  "flex-col",
  "items-center",
  "justify-center",
  "gap-5",
  "p-12",
  "border-b",
  "border-[#676e5c]",
);
