import { useEffect, useRef, useState } from "react";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { atom, useAtom } from "jotai/index";
import { getPlaceInfo, imgUrl } from "../utils";
import { Loader } from "./Loader";

export const placeSection = atom<boolean>(false);

const AnimatedText = ({
  variantsHeading,
  isScrolled,
  children,
}: {
  variantsHeading: any;
  isScrolled: any;
  children: any;
}) => {
  return (
    <div className="overflow-hidden">
      <motion.div
        className="translate-y-10"
        variants={variantsHeading}
        initial={false}
        animate={isScrolled ? "scrolled" : "initial"}
        transition={{ duration: 1 }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export const Place = () => {
  const ref = useRef<HTMLDivElement>(null);
  const refer = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll();
  const [isScrolled, setIsScrolled] = useState(false);
  const [text, setText] = useState(false);
  const [_, setAfterFirstSection] =
    useAtom<boolean>(placeSection);
  const [placeInfo, setPlaceInfo] = useState<any>(undefined);

  useEffect(() => {
    (async () => {
      const ret = await getPlaceInfo();
      setPlaceInfo(ret.data);
    })()
  }, []);

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (ref.current) {
      if (ref.current.getBoundingClientRect().top < 50) {
        setAfterFirstSection(true);
      } else {
        setAfterFirstSection(false)
      }
      if (ref.current.getBoundingClientRect().top < 300) {
        setIsScrolled(true);
      }
    }
    if (refer.current) {
      if (refer.current.getBoundingClientRect().top < 500) {
        setText(true);
      }
    }
  });

  const variantsHeading = {
    initial: {
      translateY: "100%",
    },
    scrolled: {
      translateY: "0%",
    },
  };


  return (
    <div
      ref={ref}
      className="text-[#b08353] lg:text-2xl py-20 rajdhani-regular"
    >
      {placeInfo?
      <div className="container m-auto px-5">
        <div className="flex flex-col gap-20 lg:gap-40">
          <div className="flex max-lg:flex-col gap-10">
            <div className="flex flex-col gap-20 lg:pt-20 flex-1">
              <div className="lg:max-w-[30vw]">
                <AnimatedText
                  variantsHeading={variantsHeading}
                  isScrolled={isScrolled}
                  children={<div dangerouslySetInnerHTML={{ __html: placeInfo.msg1 }} />}
                ></AnimatedText>
              </div>
              <img
                className="rounded-lg max-w-[70vw] lg:max-w-[30vw] lg:h-[500px] object-cover"
                src={`${imgUrl}${placeInfo.img2}`}
                alt="outdoor_1"
              />
              <div className="lg:max-w-[30vw]">
                <AnimatedText
                  variantsHeading={variantsHeading}
                  isScrolled={isScrolled}
                  children={<div dangerouslySetInnerHTML={{ __html: placeInfo.msg2 }} />}
                ></AnimatedText>
              </div>
            </div>
            <div className="flex flex-col gap-20 lg:gap-40 flex-1">
              <img
                className="rounded-lg max-w-[70vw] lg:max-w-[40vw] lg:h-[600px] object-cover  self-end"
                src={`${imgUrl}${placeInfo.img1}`}
                alt="outdoor_1"
              />
              <img
                className="rounded-lg max-w-[70vw] lg:max-w-[30vw] max-h-[600px] object-cover"
                src={`${imgUrl}${placeInfo.img3}`}
                alt="outdoor_1"
              />
            </div>
          </div>
          {/*<div>*/}
          {/*  <img*/}
          {/*    className="rounded-lg lg:max-w-[75%] h-auto object-cover m-auto"*/}
          {/*    src="/images/outdoor/outdoor_6.JPEG"*/}
          {/*    alt="outdoor_1"*/}
          {/*  />*/}
          {/*</div>*/}
          <div
            ref={refer}
            className="flex max-lg:flex-col lg:flex-row-reverse gap-10"
          >
            <div>
              <div className="overflow-hidden">
                <motion.div
                  variants={variantsHeading}
                  initial={false}
                  animate={text ? "scrolled" : "initial"}
                  transition={{ duration: 0.5 }}
                  className=""
                ><div dangerouslySetInnerHTML={{ __html: placeInfo.msg3 }} /></motion.div>
              </div>
            </div>
            <img
              className="rounded-lg lg:max-w-[40vw] max-h-[600px] object-cover"
              src={`${imgUrl}${placeInfo.img4}`}
              alt="outdoor_1"
            />
          </div>
        </div>
      </div>
      :
      <div className="arealoader">
        <Loader />
      </div>
      }
    </div>
  );
};
