import React, { useRef } from "react";
import classNames from "classnames";
import { motion, useScroll, useTransform } from "framer-motion";
import {
  entranceHeadingSecondVariants,
  entranceHeadingVariants,
  entranceImageVariants,
  entranceImageWrapperVariants,
  entranceMiddleTextVariants,
  entranceWrapperVariants,
} from "./Header/variants";

const MiddleText = () => {
  const { scrollY } = useScroll();
  const fontSize = useTransform(
    scrollY,
    [0, window.innerHeight],
    ["2vh", "1vh"],
  );

  return (
    <div className="overflow-hidden">
      <motion.div
        animate="initial"
        variants={entranceMiddleTextVariants}
        className="text-amber-50 rajdhani-regular text-[3vh] leading-normal text-center px-3"
      >
        <motion.div style={{ fontSize }}>
          Wyjątkowe miejsce na poprawę samopoczucia poprzez kontakt z naturą
        </motion.div>
      </motion.div>
    </div>
  );
};

export const Entrance = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll();

  // @ts-ignore
  // const opacity = useTransform(scrollY, [0, ref.current && ref.current.offsetHeight], [0, 0.5]);
  // @ts-ignore
  // const scale = useTransform(scrollY, [0, ref.current && ref.current.offsetHeight], [1, 1.2]);
  const opacity = useTransform(scrollY, [0, window.innerHeight], [0, 0.5]);
  const scale = useTransform(scrollY, [0, window.innerHeight], [1, 1.2]);
  const gap = useTransform(scrollY, [0, window.innerHeight], ["10vw", "0vw"]);
  const fontSize = useTransform(
    scrollY,
    [0, window.innerHeight],
    ["5vh", "2vh"],
  );

  return (
    <motion.div
      ref={ref}
      animate={"initial"}
      variants={entranceWrapperVariants}
      className={entranceWrapperClassnames}
    >
      <motion.div
        style={{ opacity }}
        className={entranceWrapperOpacityClassnames}
      />
      <motion.div
        className="fixed"
        animate={"initial"}
        variants={entranceImageWrapperVariants}
      >
        <motion.img
          style={{ scale }}
          // animate={"initial"}
          // variants={entranceImageVariants}
          src="/images/view2.jpeg"
          alt="view"
          className="w-screen h-screen object-cover"
        />
      </motion.div>
      <motion.div style={{ gap }} className={entranceWrapperTextClassnames}>
        <div className="flex flex-col gap-4 items-center">
          <div className="overflow-hidden text-center">
            <motion.h1
              animate={"initial"}
              variants={entranceHeadingVariants}
              className={headingClasses}
            >
              <motion.div style={{ fontSize }}>
                Zielony Dzięcioł Noclegi
              </motion.div>
            </motion.h1>
          </div>
          {/*<div className="overflow-hidden">*/}
          {/*  <motion.h2*/}
          {/*    initial={{*/}
          {/*      transform: "translateY(180%) rotate(-5deg) translateZ(0px)",*/}
          {/*    }}*/}
          {/*    animate={{*/}
          {/*      transform: "translateY(0%) rotate(0deg) translateZ(0px)",*/}
          {/*    }}*/}
          {/*    transition={{ delay: 2.5, duration: 1 }}*/}
          {/*    className={headingClasses}*/}
          {/*  >*/}
          {/*    <motion.div style={{ fontSize }}>Noclegi</motion.div>*/}
          {/*  </motion.h2>*/}
          {/*</div>*/}
        </div>
        <MiddleText />
        <div className="overflow-hidden">
          <motion.h2
            animate={"initial"}
            variants={entranceHeadingSecondVariants}
            className={headingClasses}
          >
            <motion.div style={{ fontSize }}>w dolinie rzeki San</motion.div>
          </motion.h2>
        </div>
      </motion.div>
    </motion.div>
  );
};

const entranceWrapperClassnames = classNames(
  "flex",
  "items-center",
  "justify-center",
  "h-screen",
  "relative",
);

const entranceWrapperOpacityClassnames = classNames(
  "bg-black",
  "fixed",
  "top-0",
  "bottom-0",
  "left-0",
  "right-0",
  "z-10",
);

const entranceWrapperTextClassnames = classNames(
  "fixed",
  "top-0",
  "left-0",
  "bottom-0",
  "right-0",
  "flex",
  "flex-col",
  "justify-center",
  "items-center",
);

const headingClasses = classNames(
  "text-6xl",
  "text-amber-50",
  "leading-normal",
  // "poiret-one-regular",
  "stmarie-thin-light",
);
