import {
  burgerElementsWrapperVariants,
  burgerVariants,
  textVariants,
} from "./variants";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useAtom } from "jotai/index";
import { headerMenuOpen, headerMenuScrolled } from "../Header";
import { placeSection } from "../Place";

export const Burger = () => {
  const [menuOpen, setMenuOpen] = useAtom<boolean>(headerMenuOpen);
  const [isScrolled] = useAtom<boolean>(headerMenuScrolled);
  const [afterFirstSection] = useAtom<boolean>(placeSection);

  const handleClick = () => {
    setMenuOpen(!menuOpen);
    if (menuOpen) {
      document.body.style.overflowY = "";
    } else {
      document.body.style.overflowY = "hidden";
    }
  };

  return (
    <motion.div
      variants={burgerVariants}
      animate={isScrolled ? "scrolled" : "initial"}
      transition={{ duration: 0.5 }}
      className={classNames(
        burgerWrapperClasses,
        !menuOpen && afterFirstSection
          ? "bg-[#fffcf4]/80 !border-[#b08353] before:!border-[#b08353]"
          : "",
      )}
    >
      <motion.div
        variants={burgerElementsWrapperVariants}
        animate={isScrolled ? "scrolled" : "initial"}
        transition={{ duration: 0.5 }}
        className={burgerElementsWrapperClasses}
      >
        <div
          className="w-[50px] h-[50px] flex justify-center items-center cursor-pointer"
          onClick={handleClick}
        >
          <div className={burgerClasses}>
            <div
              className={classNames(
                "burger-span-first",
                "top-[1px]",
                burgerSpanClasses,
                menuOpen && "open",
                !menuOpen && afterFirstSection && "bg-[#b08353]",
              )}
            />
            <div
              className={classNames(
                "burger-span-second",
                "top-[calc(100%_-_1px)]",
                burgerSpanClasses,
                menuOpen && "open",
                !menuOpen && afterFirstSection && "bg-[#b08353]",
              )}
            />
          </div>
        </div>

        <motion.div
          variants={textVariants}
          animate={isScrolled ? "scrolled" : "initial"}
          transition={{ duration: 0.5 }}
          className={classNames("burger-text", menuTextClasses)}
        >
          MENU
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

const burgerWrapperClasses = classNames(
  "burger",
  "fixed",
  "lg:top-[50px]",
  "lg:left-[50px]",
  "max-lg:right-[20px]",
  "max-lg:top-[20px]",
  "border",
  "border-[#E0E2DE]",
  "rounded-lg",
  "h-[52px]",
  "inline-flex",
  "items-center",
  "justify-center",
  "w-[150px]",
  "max-lg:!w-[60px]",
  // "p-5",
  "z-30",
);

const burgerClasses = classNames("relative", "w-5", "h-3", "cursor-pointer");

const burgerSpanClasses = classNames(
  "absolute",
  "left-0",
  "w-full",
  "h-[2px]",
  "bg-[#E0E2DE]",
  "transition-all",
);

const burgerElementsWrapperClasses = classNames(
  "flex",
  "gap-5",
  "items-center",
  "justify-center",
);

const menuTextClasses = classNames(
  "text-[#E0E2DE]",
  "max-sm:!hidden",
  "text-[17px]",
  "rajdhani-regular",
);
