import { menuImageVariants } from "./variants";
import classNames from "classnames";
import { menuItems } from "./MenuList";
import { motion } from "framer-motion";
import { useAtom } from "jotai/index";
import { headerMenuImg } from "../Header";

export const MenuImages = () => {
  const [imageUrl] = useAtom<number | undefined>(headerMenuImg);

  return (
    <motion.div
      className="relative flex-1 flex justify-center items-center max-sm:hidden"
      initial="initial"
      animate="open"
      exit="initial"
      variants={menuImageVariants}
    >
      <img
        className={classNames(menuImgClasses, imageUrl === 0 && "opacity-100")}
        src={"/images/logo_second.jpg"}
        alt=""
        width={301}
        height={445}
      />
      {menuItems.map((item, index) => (
        <img
          className={classNames(
            menuImgClasses,
            imageUrl === index + 1 && "opacity-100",
          )}
          src={item.src}
          alt=""
          width={301}
          height={445}
        />
      ))}
    </motion.div>
  );
};

const menuImgClasses = classNames(
  "absolute",
  "transition-all",
  "duration-1000",
  "rounded-tl-full",
  "rounded-tr-full",
  "object-cover",
  "w-[301px]",
  "h-[445px]",
  "opacity-0",
);
