import classNames from "classnames";
import { isMobile } from "react-device-detect";
import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
} from "framer-motion";
import { menuBorderVariants, menuVariants } from "./Header/variants";
import { atom, useAtom } from "jotai";
import { Burger } from "./Header/Burger";
import { MenuList } from "./Header/MenuList";
import { MenuImages } from "./Header/MenuImages";

export const headerMenuOpen = atom<boolean>(false);
export const headerMenuScrolled = atom<boolean>(false);
export const headerMenuImg = atom<number | undefined>(0);

export const Header = () => {
  const { scrollY } = useScroll();
  const [menuOpen] = useAtom<boolean>(headerMenuOpen);
  const [isScrolled, setIsScrolled] = useAtom<boolean>(headerMenuScrolled);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const enterThreshold = 200;
    const exitThreshold = 100;

    if (isMobile) {
      return null;
    }

    if (latest >= enterThreshold && !isScrolled) {
      setIsScrolled(true);
    } else if (latest <= exitThreshold && isScrolled) {
      setIsScrolled(false);
    }
  });

  return (
    <>
      {/*<div className="fixed top-0 left-0 right-0 p-10 z-20 rajdhani-regular">*/}
      <Burger />
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial="initial"
            animate="open"
            exit="initial"
            variants={menuVariants}
            className={menuClasses}
          >
            <div
              className={classNames(
                "flex",
                "justify-center",
                "items-center",
                "h-full",
                "text-[#E0E2DE]",
                "gap-40",
              )}
            >
              <MenuList />
              <motion.div
                initial="initial"
                animate="open"
                exit="initial"
                variants={menuBorderVariants}
                transition={{ duration: 1 }}
                className={menuBorderElementClasses}
              />
              <MenuImages />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const menuClasses = classNames(
  // "bg-black",
  "fixed",
  "z-20",
  "top-0",
  "bottom-0",
  "left-0",
  "right-0",
  "bg-[#202e21]",
  // "bg-[#364237]",
  "overflow-hidden",
  "rajdhani-regular",
);

const menuBorderElementClasses = classNames(
  "absolute",
  "w-[1px]",
  "bg-[#676e5c]",
  "left-1/2",
  "top-1/2",
  "translate-x-[-50%]",
  "translate-y-[-50%]",
  "h-[calc(100%_-_80px)]",
  "clip-path-[inset(0px)]",
  "max-sm:hidden",
);
